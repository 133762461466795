@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style-type: none;
	font-family: "Open Sans";
}

.main {
	display: flex;
	background-color: #f8fafb;
	flex-direction: column;
}

.dashBoard {
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	padding: 0 1rem;
}

/* NAVBAR */

.logo {
	width: 130px;
	height: 60px;
}

.navBar {
	height: 80px;
	padding: 0 1rem;
	background-color: #ffffff;
	border-right: 2px solid #f8fafb;
}

.nav-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
}

.nav-content {
	position: absolute;
	top: 80px;
	left: 0;
	width: 100%;
	display: flex;
	transition: all 0.1s;
	flex-direction: column;
	height: 100vh;
	z-index: 10;
	background-color: #ffff;
}
.hide {
	display: none;
}

.hamburger {
	width: 35px;
	height: 25px;
}

.title-icon {
	width: 20px;
	height: 20px;
}

.title-list-item {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 0.5rem;
	padding: 1rem 0;
	padding-left: 1rem;
}

.category_name {
	font-size: 1.3rem;
	font-weight: 600;
}

.arrow-icon {
	width: 10px;
}
.rotate-arrow {
	transform: rotate(90deg);
}
.rotate-arrow-180 {
	transform: rotate(180deg);
}

.sensor-company {
	margin-left: 2rem;
}

.sensors {
	margin-left: 2rem;
}

.sensors li {
	padding: 0.3rem 0;
}

.sensors li a {
	text-decoration: none;
}

.sensor-company-name {
	display: flex;
	gap: 10px;
	padding: 0.7rem 0;
	color: #0c0c70;
}

.sensor-company-name:hover {
	cursor: pointer;
}

/* DASHBOARD */
.grafice {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;

	justify-content: center;
	align-items: center;

	margin-bottom: 2rem;
}
.dashboard_header {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.limit-pm-container {
	margin-bottom: 1rem;
	padding: 1rem;
}

.limit-pm-header {
	display: flex;
	gap: 10px;
}

.limit-pm-content {
	display: flex;
	gap: 10px;
	flex-direction: column;
	flex-wrap: wrap;
}

.limit-pm-type h3 {
	margin-bottom: 0.5rem;
}

.limit-pm-type {
	max-width: 400px;
}

.limit-pm-type ul li {
	display: flex;
	justify-content: space-between;
	padding: 0.25rem 1rem;
}

.limit-pm-type ul li:nth-of-type(1) {
	background-color: #50f0e6;
}
.limit-pm-type ul li:nth-of-type(2) {
	background-color: #50ccaa;
}
.limit-pm-type ul li:nth-of-type(3) {
	background-color: #f0e641;
}
.limit-pm-type ul li:nth-of-type(4) {
	background-color: #ff5050;
}
.limit-pm-type ul li:nth-of-type(5) {
	background-color: #960032;
}
.limit-pm-type ul li:nth-of-type(6) {
	background-color: #7d2181;
}

.dash_title {
	font-size: 1.7rem;
	text-align: left;
	margin-top: 2rem;
}

.dash_data {
	margin: 2rem auto;
	background-color: #ffffff;
	border-radius: 5px;
	box-shadow: -1px 1px 10px -5px rgba(0, 0, 0, 0.16);
}

.dash_values {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	border: 2px solid #f8fafb;
}

.dash_value {
	display: flex;
	flex-direction: column-reverse;
	margin-right: 1rem;
	padding: 1rem;
}

.data_value {
	font-size: 1.25rem;
}

.sensor_producer {
	margin-top: 0.5rem;
}

.producer a {
	text-decoration: none;
	padding-left: 5px;
}

/* SPINNER */
.spinner-modal {
	width: 100%;
	height: 100%;
	background-color: #ffffff;
}
.lds-ellipsis {
	display: inline-block;
	position: absolute;
	width: 80px;
	height: 80px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: rgb(156, 207, 232);
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

.pm-graph {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	padding: 1rem 0;
	padding-right: 1.5rem;
}

.pm-graph-info ul {
	display: flex;
	gap: 10px;
}

.pm-graph-info ul li {
	display: flex;
	align-items: center;
	gap: 5px;
}

.chart_pms {
	width: 100%;
}

.duo_chart {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.c_style {
	background-color: #ffffff;
	border: 2px solid #f8fafb;
	box-shadow: -1px 1px 10px -5px rgba(0, 0, 0, 0.16);
	border-radius: 5px;
}
.duo_chart_chart {
	width: 100%;
	padding: 1rem 0;
	padding-right: 1.5rem;
}

.chart {
	width: 100%;
	padding: 1rem 0;
	padding-right: 1.5rem;
}

.change_time_container {
	display: flex;
	justify-content: left;
	width: fit-content;
	margin-bottom: 10px;
	border-radius: 5px;
	align-items: center;
	gap: 10px;
}

.time_button {
	padding: 0.25rem 1rem;
	border: none;
	font-weight: 800;
	font-size: 1rem;
	background-color: #f8fafb;
}

.time_button:hover {
	cursor: pointer;
}

.active {
	background-color: black;
	color: white;
}

.landing-page {
	display: flex;
	flex-direction: column;
	background-color: #ffff;
	padding: 1rem 0.5rem;
}

.landing-header {
	color: #0097be;
	margin: 0 auto;
	margin-top: 1rem;
	text-align: center;
	font-size: 1.3rem;
	padding: 0 0.5rem;
	letter-spacing: 1.5px;
	margin-bottom: 1rem;
	max-width: 500px;
}
.l-im {
	object-fit: cover;
	width: 100%;
	max-height: 450px;
}
.landing-image {
	max-width: 550px;
	object-fit: cover;
	margin: 0 auto;
}

.landing-info {
	padding: 0 1rem;
	margin: 0 auto;
}

.landing-info p {
	margin-top: 1rem;
	text-align: justify;
	font-size: 1.1rem;
	max-width: 550px;
}
@media (min-width: 900px) {
	.lds-ellipsis {
		position: relative;
	}

	.main {
		width: 100%;
		height: 100vh;
		flex-direction: row;
	}
	.ham-h {
		display: none;
	}

	.navBar {
		height: 100vh;
		width: 250px;
		position: relative;
		top: 0;
	}

	.nav-content {
		position: inline-block;
		height: auto;
	}

	.hide {
		display: block;
	}

	.nav-header {
		justify-content: center;
	}

	.pm-graph {
		flex-direction: row;
	}

	.pm-graph-info ul {
		flex-direction: column;
		width: 100px;
		align-items: center;
	}

	.duo_chart {
		flex-direction: row;
	}

	.limit-pm-content {
		flex-direction: row;
		width: 100%;
		justify-content: space-around;
		margin-bottom: 10px;
	}
	.limit-pm-content h3 {
		text-align: center;
		margin-top: 10px;
	}
	.limit-pm-content ul {
		width: 350px;
		max-width: 500px;
	}

	.landing-page {
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.landing-image {
		max-width: 100%;
		max-width: 450px;
	}

	.landing-header {
		margin-top: 3.5rem;
		font-size: 1.8rem;
		max-width: 800px;
		margin-bottom: 2rem;
	}

	.landing-info p {
		font-size: 1rem;
		margin-top: 3rem;
		letter-spacing: 1.5px;
		line-height: 1.5;
	}
}
